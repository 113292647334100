import { __decorate } from "tslib";
import EventModule from "@/store/module/shared/EventModule";
import UserModule from "@/store/module/user/UserModule";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
let UserEventsResults = class UserEventsResults extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.page = 1;
        this.itemsPerPage = 25;
        this.pagesCount = 0;
        this.headers = [
            {
                text: "Событие",
                sortable: false,
                value: "name",
            },
            {
                text: "Дата",
                sortable: false,
                value: "date",
            },
            {
                text: "Дисциплина",
                sortable: false,
                value: "discipline",
            },
            {
                text: "Квалификация - очки",
                sortable: true,
                value: "qualificationScore",
            },
            {
                text: "Квалификация - место",
                sortable: true,
                value: "qualificationPosition",
            },
            {
                text: "Финал - место",
                sortable: true,
                value: "finalPosition",
            },
            {
                text: "Команда",
                sortable: false,
                value: "team",
            },
            {
                text: "Команда квал. - очки",
                sortable: true,
                value: "teamQualificationScore",
            },
            {
                text: "Команда квал. - место",
                sortable: true,
                value: "teamQualificationPosition",
            },
            {
                text: "Команда финал - место",
                sortable: true,
                value: "teamFinalPosition",
            },
        ];
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get events() {
        return this.$store.getters[EventModule.types.getters.EVENTS];
    }
    get eventsTable() {
        return this.$store.getters[EventModule.types.getters.EVENTS_TABLE];
    }
    getTeamFinalPositionView(position) {
        if (position === null)
            return "-";
        return String(position);
    }
    get items() {
        const items = [];
        if (!this.user)
            return items;
        if (!this.user.eventsParticipant)
            return items;
        if (this.events.length == 0)
            return items;
        for (const participant of this.user.eventsParticipant) {
            if (!participant.individualResult)
                continue;
            const event = this.eventsTable[participant.eventId];
            let disciplineView = "";
            if (participant.discipline && participant.discipline.discipline) {
                disciplineView = `${participant.discipline.disciplineGroup.name} | ${participant.discipline.discipline.name}`;
            }
            else if (participant.discipline && participant.discipline.disciplineGroup) {
                disciplineView = participant.discipline.disciplineGroup.name;
            }
            const hasTeamResult = !!participant.teamParticipant && !!participant.teamParticipant.teamResult;
            const teamResultsView = {
                team: "-",
                teamQualificationScore: "-",
                teamQualificationPosition: "-",
                teamFinalPosition: "-",
            };
            if (hasTeamResult) {
                const team = event.ianseoProtocol.teams.find((t) => t.id === participant.teamParticipant.teamId);
                if (team) {
                    teamResultsView.team = team.name;
                    teamResultsView.teamQualificationScore = String(participant.teamParticipant.teamResult.qualificationScore);
                    teamResultsView.teamQualificationPosition = String(participant.teamParticipant.teamResult.qualificationPosition);
                    teamResultsView.teamFinalPosition = this.getTeamFinalPositionView(participant.teamParticipant.teamResult.finalPosition);
                }
            }
            const item = {
                name: event.name,
                date: moment(event.startDate).format("YYYY-MM-DD"),
                discipline: disciplineView,
                qualificationScore: participant.individualResult.qualificationScore,
                qualificationPosition: participant.individualResult.qualificationPosition,
                finalPosition: participant.individualResult.finalPosition,
                ...teamResultsView,
            };
            items.push(item);
        }
        return items;
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        if (this.events.length === 0) {
            await this.$store.dispatch(EventModule.types.actions.GET_EVENTS);
        }
        this.loading = false;
    }
};
UserEventsResults = __decorate([
    Component({})
], UserEventsResults);
export default UserEventsResults;
